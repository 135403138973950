<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3>{{ $t('components.composition.add.title') }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>

            <!-- Composition -->
            <v-col cols="12">
              <v-row no-gutters>
                <template v-for="(material, index) of form.composition">
                  <v-col cols="12" :key="index" class="my-4">
                    <ComponentMaterialInput
                        :material="material.value"
                        :percentage="material.percentage"
                        @updated="(updatedMaterial) => updateComposition(updatedMaterial, index)"
                    />
                  </v-col>
                </template>

                <!-- Button -->
                <v-col cols="12">
                  <v-btn @click="addRawMaterial" block>
                    {{ $t('components.buttons.addMaterial') }}
                  </v-btn>
                </v-col>
              </v-row>

            </v-col>
            <v-col cols="12">
              <AlertBar />
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
            class="mx-3"
            :is-loading-btn-save="isLoadingBtn.btnSave"
            @submit="customSubmit"
            @cancel="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin            from "@/mixins/dialog"
import formRules              from "@/mixins/formRules";

export default {
  name: "ComponentCompositionDialog",

  mixins: [dialogMixin, formRules],

  components: {
    ComponentMaterialInput: () => import("@/components/Components/ComponentMaterialInput"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  props: {
  },

  data() {
    return {
      isLoadingBtn: {btnSave: false},

      defaultForm: {
        composition: []
      }
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        for (let i = 0; i < this.form.composition.length; i++) {
          this.form.composition[i] = {
            percentage: this.form.composition[i].percentage,
            material: this.form.composition[i].material['id'] ? this.form.composition[i].material['id'] : this.form.composition[i].material
          }
        }
        this.submitEdit();
      }
    },

    submitEdit() {

      this.$emit("submit", this.form.composition);
    },

    updateComposition(val, index) {
      this.form.composition[index] = val;
    },

    addRawMaterial() {
      this.form.composition.push({
        material: null,
        percentage: 1
      })
    }

  },

  computed: {
  }
}
</script>

<style scoped>

</style>
